import React from 'react';
import './view.less';

/**
 * @class CnSmartOneSearch
 * @description 
 */
export default class CnSmartOneSearch extends React.Component {
  static displayName = 'CnSmartOneSearch';

  constructor(props) {
    super(props);
  }

  render() {
    return this.props.children;
  }
}
