import React from 'react';
import './view.less';

/**
 * @class CnGloryDetail
 * @description 
 */
export default class CnGloryDetail extends React.Component {
  static displayName = 'CnGloryDetail';

  constructor(props) {
    super(props);
  }

  render() {
    return this.props.children;
  }
}
